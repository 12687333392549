import React, { useState } from 'react'
import Form from '../Form/Form'
import FormMessage from '../FormMessage/FormMessage'

import image from '../../images/img-kontakt.jpg'

import './Bewerben.sass'

const Bewerben = (props) => {

	const [isSent, setIsSent] = useState(false)

	const userId = props.userId

	let ort = ""
	let urlParams = ""

	const urlParameters = new URLSearchParams(window.location.search)

	for (const [key, value] of urlParameters) {
		if (key === 'ort') {
			ort = value
		}
		else {
			urlParams += `${key}: ${value}, `
		}
	}

	const [formValues, setFormValues] = useState({
		name: "",
		nachname: "",
		email: "",
		telefon: "",
		urlparameter: urlParams,
		ort: ort,
		ortswahl: ort,
		page: window.location.origin,
		consent: false,
		kontakt: false,
		error: ""
	})

	return (
		<section id="bewerben">
			<div className="left--content">
				<div className="form--content">
					<h2>Deine Bewerbung</h2>
					<p>Vielen Dank für Deine Bewerbung und Dein damit verbundenes Interesse an einer Tätigkeit bei KiKxxl!</p>
					<Form isSent={isSent} setIsSent={setIsSent} formValues={formValues} setFormValues={ setFormValues } userId={ userId }/>
				</div>
			</div>
			<div className="right--content" style={{backgroundImage: `url(${image})`}}></div>
			<FormMessage active={isSent} formValues={formValues} userId={ userId }/>
		</section>
	)
}

export default Bewerben
