// create unique id



const getID = () => { 

	let date = new Date().getTime(); 
	date = btoa(date)
	date = date.substring(0, date.length - 2)
	let op = date.substring(date.length - 8).toUpperCase()
	op = "2-" + op

	return op;
}

export default getID
