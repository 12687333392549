import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet'

ReactDOM.render(
	<React.StrictMode>
		<Helmet>
			<script async src="https://2do-dsgvo.de/ccm19/public/app.js?apiKey=c30783b0149b3ec195967741a0e791944f2597527dfa556e&amp;domain=1e0e7b6&amp;lang=de_DE" referrerpolicy="origin"></script>
		</Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
