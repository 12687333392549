import React, { useState } from 'react'
import axios from 'axios'

import './Form.sass'

const getOrt = new URLSearchParams(window.location.search).get("ort");

const Form = ({ isSent, setIsSent, formValues, setFormValues, userId }) => {

	const [isSending, setIsSending] = useState(false)
	
	let baseUrl = 'https://kikxxl-jobs.info/handleSubmit.php'
	if (window.location.href.indexOf('localhost') > -1) {
		baseUrl = 'http://dev.digital/kikxxl/public/handleSubmit.php'
	}

	const orte = [
		'Bochum',
		"Bottrop",
		"Bremen",
		"Dortmund",
		"Osnabrück",
		"Recklinghausen"
	];

	const handleFormSubmit = (e) => {
		e.preventDefault()

		if (formValues.name === "" ||
			formValues.nachname === "" ||
			formValues.email === "" ||
			formValues.telefon === "" ||
			!formValues.consent) {
			setFormValues({ ...formValues, error: "Bitte alle Felder ausfüllen" })
			return
		}

		setIsSending(true)

		const ort = formValues.ort === "Bottrop" ? 'BTR' : formValues.ort


		let formData = new FormData()
		formData.append('name', formValues.name)
		formData.append('nachname', formValues.nachname)
		formData.append('email', formValues.email)
		formData.append('telefon', formValues.telefon)
		formData.append('page', formValues.page)
		formData.append('urlparameter', formValues.urlparameter)
		formData.append('ort', ort)
		formData.append('ortswahl', formValues.ortswahl)
		formData.append('userId', userId)
		formData.append('consent', formValues.consent === true ? 'Ja' : 'Nein')
		formData.append('kontakt', formValues.kontakt === true ? 'Ja' : 'Nein')


		axios({
			method: 'post',
			url: baseUrl,
			data: formData,
			config: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		})
			.then(response => {
				window.dataLayer.push({
					event: 'form_send'
				})
				console.log(response);
				setIsSent(true)
				setIsSending(false)
			})
			.catch(error => setFormValues({...formValues, error: error.response.data}))
	}
	return (
	<>
		{
			
				(<form style={{
					pointerEvents: isSending ? 'none' : 'all',
					opacity: isSending ? '0.15' : '1'
				}}>
				<input type="hidden" name="unique_id" />
					
					<select name="ortswahl" onLoad={e => setFormValues({...formValues, ortswahl: e.target.value})} onChange={e => setFormValues({...formValues, ortswahl: e.target.value})}>
					
						{getOrt && <option value={getOrt}>{getOrt}</option>}
						
						{
							// eslint-disable-next-line
							orte.map((ort, i) => {
						if (!getOrt || getOrt !== ort) {
							return <option value={ort} key={i}>{ort}</option>
						}
					})}	
				</select>
				
				<label>
					Vorname*
					<input type="text" name="name" placeholder="Vorname"value={formValues.name} onChange={(e) => setFormValues({...formValues, name: e.target.value})}/>
				</label>
					
				<label>
					Nachname*
					<input type="text" name="nachname" placeholder="Nachname" value={formValues.nachname} onChange={(e) => setFormValues({ ...formValues, nachname: e.target.value })} />
				</label>
				<label>
					E-Mail-Adresse*
					<input type="email" name="email" placeholder="Email"value={formValues.email} onChange={(e) => setFormValues({...formValues, email: e.target.value})}/>
				</label>
				<label>
					Telefonnummer*
					<input type="text" name="telefon" placeholder="Telefon"value={formValues.telefon} onChange={(e) => setFormValues({...formValues, telefon: e.target.value})}/>
				</label>
				<input type="hidden" name="page" value={formValues.page}/>
				<input type="hidden" name="urlparameter" placeholder="URL Parameter" value={formValues.urlparameter} readOnly />
				
				<label className="cb--group">
					<input
						type="checkbox"
						name="consent"
						id="consent"
						checked={formValues.consent}
						onChange={e => setFormValues({ ...formValues, consent: e.target.checked })} />
					<p><span>Ich akzeptiere die Datenschutzrichtlinen der KiKxxl GmbH, diese sind <a href="https://www.kikxxl.de/datenschutz/" target="_blank" rel="noopener noreferrer">hier</a> zu finden.  Ich erlaube der KiKxxl GmbH zur Bearbeitung meiner Bewerbung Kontakt mit mir aufzunehmen, über Telefon, SMS, WhatsApp oder E-Mail.*</span></p>
				</label>
				
				<label className="cb--group">
					<input
						type="checkbox"
						name="kontakt"
						id="kontakt"
						checked={formValues.kontakt}
						onChange={e => setFormValues({ ...formValues, kontakt: e.target.checked })}
					/>
					<p>Ich bin damit einverstanden, dass meine Daten für zukünftig zu besetzende Stellen und damit verbundene Kontaktversuche für maximal 12 Monate gespeichert werden.</p>
				</label>

				<button className="main--button" onClick={e => handleFormSubmit(e)}>Jetzt bewerben</button>
				<p>* Pflichtfelder</p>
						<strong style={{ display: 'block', padding: "20px", color: "red" }} dangerouslySetInnerHTML={{ __html: formValues.error }}></strong>
		</form>)
	}
		
	</>)
}

export default Form
