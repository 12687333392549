import React from 'react'

import './Vorteile.sass'

import Vorteil from './Vorteil/Vorteil'

import icnAuszeichnung from './../../images/icn-auszeichnung.svg'
import icnStandorte from './../../images/icn-standorte.svg'
import icnBenefits from './../../images/icn-benefits.svg'
import icnGehalt from './../../images/icn-gehalt.svg'
import icnEinarbeitung from './../../images/icn-einarbeitung.svg'
import image from './../../images/img-vorteile.jpg'

const getOrt = new URLSearchParams(window.location.search).get("ort");

console.log(getOrt)

const data = [
	{
		image: icnAuszeichnung,
		title: 'Top-Arbeitgeber 2023',
		text: 'Seit mehreren Jahren wird KiKxxl von der Universität St. Gallen im Wettbewerb „Top Arbeitgeber des Jahres“auf die vorderen Plätze gewählt. Im Jahr 2023 belegen wir erneut Platz 1 und sind „Bester Arbeitgeber des Jahres“ in unserer Größenklasse.'
	},
	{
		image: icnStandorte,
		title: 'Viele Standorte',
		text: 'All unsere Standorte in Osnabrück, Bremen, Dortmund, Bochum und Recklinghausen verfügen über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
	},
	{
		image: icnBenefits,
		title: 'Geniale Benefits',
		text: 'Als KiKxxl Mitarbeiter hast Du Zugriff auf eine ganze Reihe von attraktiven Benefits: Von sehr günstigen Sportangeboten und betrieblicher Altersvorsorge bis zu Weight Watchers at Work-Kursen.'
	},
	{
		image: icnGehalt,
		title: 'Gutes Gehalt',
		text: 'Deine Verdienstmöglichkeiten liegen (erfolgsabhängig) zwischen 2.200,- Euro und 3.200,- Euro/Monat (Basisvergütung + nach oben hin offene Provision).  Im Inboud-Service liegst Du zwischen 2.200,- Euro und 2.500,- Euro/Monat (Basisvergütung + nach oben hin offene Provision). '
	},
	{
		image: icnEinarbeitung,
		title: 'Erstklassige Einarbeitung',
		text: 'Telefonischer Kundenberater (m/w/d) bei KiKxxl – wir bereiten Dich ausführlich auf Deinen neuen Job vor – das macht den Job ideal für Quereinsteiger!'
	},
]

// Ortsspezifische Texte
let vorteil = ''
switch (getOrt) {
	case 'Osnabrück':
		vorteil = "in Osnabrück"
		data[1].title = 'Standort Osnabrück'
		data[1].text = 'Unser Standort in der Mindener Straße verfügt über ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Deine Verdienstmöglichkeiten liegen (erfolgsabhängig) zwischen 2.200,- Euro und 3.200,- Euro/Monat (Basisvergütung + nach oben hin offene Provision).  Im Inboud-Service liegst Du zwischen 2.200,- Euro und 2.500,- Euro/Monat (Basisvergütung + nach oben hin offene Provision). '
		break;
	case 'Dortmund':
		vorteil = "in Dortmund"
			data[1].title = 'Standort Dortmund'
			data[1].text = 'Unser Standort in der ehemaligen Zechni Minster Stein in Dortmund-Eving verfügt über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
			data[3].text = 'Deine Verdienstmöglichkeiten liegen (erfolgsabhängig) zwischen 2.200,- Euro und 3.200,- Euro/Monat (Basisvergütung + nach oben hin offene Provision).  Im Inboud-Service liegst Du zwischen 2.200,- Euro und 2.500,- Euro/Monat (Basisvergütung + nach oben hin offene Provision). '
			break;
	case 'Bremen':
		vorteil = "in Bremen"
		data[1].title = 'Standort Bremen'
		data[1].text = 'Unser Standort in der Airport City verfügt über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Du erhältst bei KiKxxl in der aktiven Kundenansprache eine leistungs- und zielorientierte Vergütung. Dein Einstiegsgehalt liegt bei 12 Euro/Stunde und ist mit Prämien nach oben offen.'
		break;
	case 'Bochum':
		vorteil = "in Bochum"
		data[1].title = 'Standort Bochum'
		data[1].text = 'Unser Standort im Jahrunderthaus Bochum verfügt über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Deine Verdienstmöglichkeiten liegen (erfolgsabhängig) zwischen 2.200,- Euro und 3.200,- Euro/Monat (Basisvergütung + nach oben hin offene Provision).  Im Inboud-Service liegst Du zwischen 2.200,- Euro und 2.500,- Euro/Monat (Basisvergütung + nach oben hin offene Provision). '
		break;
	case 'Recklinghausen':
		vorteil = "in Recklinghausen"
		data[1].title = 'Standort Recklinghausen'
		data[1].text = 'Unser Standort im Herzen der Innenstadt von Recklinghausen (ehemaliges C&A Gebäude) verfügt über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Deine Verdienstmöglichkeiten liegen (erfolgsabhängig) zwischen 2.200,- Euro und 3.200,- Euro/Monat (Basisvergütung + nach oben hin offene Provision).  Im Inboud-Service liegst Du zwischen 2.200,- Euro und 2.500,- Euro/Monat (Basisvergütung + nach oben hin offene Provision). '
		break;
	case 'Bottrop':
		vorteil = "in Bottrop"
		data[1].title = 'Standort Bottrop'
		data[1].text = 'Unser Standort im Bauknecht-Quartier (Böckenhoffstraße) lieget in der Stadtmitte und verfügt über ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenfreie Parkplätze zur Verfügung.'
		data[3].text = 'Deine Verdienstmöglichkeiten liegen (erfolgsabhängig) zwischen 2.200,- Euro und 3.200,- Euro/Monat (Basisvergütung + nach oben hin offene Provision).  Im Inboud-Service liegst Du zwischen 2.200,- Euro und 2.500,- Euro/Monat (Basisvergütung + nach oben hin offene Provision). '
		break;
	default:
		data[1].text = 'All unsere Standorte in Osnabrück, Bremen, Dortmund, Bochum, Bottrop und Recklinghausen verfügen über eine ideale Anbindung mit öffentlichen Verkehrsmitteln, außerdem stehen kostenlose Parkplätze zur Verfügung.'
		data[3].text = 'Deine Verdienstmöglichkeiten liegen (erfolgsabhängig) zwischen 2.200,- Euro und 3.200,- Euro/Monat (Basisvergütung + nach oben hin offene Provision).  Im Inboud-Service liegst Du zwischen 2.200,- Euro und 2.500,- Euro/Monat (Basisvergütung + nach oben hin offene Provision). '
}

const Vorteile = () => {
	return (
 		<section id="vorteile" className="content">
			<h2>Deine Vorteile {vorteil}</h2>
			<div className="vorteile--grid">
				{data.map(vorteil => <Vorteil key={ vorteil.title} title={vorteil.title} image={vorteil.image} text={ vorteil.text}/>
				)}
				<article className="image--article" style={{backgroundImage: `url(${image})`}}></article>
			</div>
		</section>
	)
}

export default Vorteile
